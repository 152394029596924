import { useAuth } from "contexts/AuthContext"
import React from "react"
import { Outlet } from "react-router-dom"
import { LoginPage } from "./LoginPage"

export const RootLayout = (): React.JSX.Element => {
  const auth = useAuth()

  if (auth.loginState !== "successful") {
    return <LoginPage />
  }
  return <Outlet />
}
