import { Code, ConnectError } from "@connectrpc/connect"
import { useDisclosure } from "@einride/hooks"
import {
  Alert,
  Box,
  Caption,
  Card,
  Group,
  Icon,
  IconButton,
  PrimaryButton,
  SearchInput,
  SecondaryButton,
  Select,
  Skeleton,
  Stack,
  TertiaryButton,
  Text,
  TextInput,
  Title2,
  Tooltip,
} from "@einride/ui"
import { Cog } from "@emotion-icons/boxicons-solid"
import { PlugDisconnected } from "@emotion-icons/fluentui-system-regular"
import { CustomPathSheet } from "components/CustomPathSheet"
import { TopRightFlexDiv } from "components/FlexDiv"
import { GeoJSONMap } from "components/Map"
import { Base64ToFeatureCollection } from "components/PathComponents"
import { SettingsSheet } from "components/SettingsSheet"
import { useToasts } from "components/Toast"
import FuzzySearch from "fuzzy-search"
import { Path, Site } from "gen/einride/aet_path_service/v1"
import { RemoteInterface } from "gen/einride/rd_operator_interface/v1/remote_interface_pb"
import { StreamVehicleLiveDataResponse } from "gen/einride/rd_operator_interface/v1/vehicle_live_pb"
import { Vehicle } from "gen/einride/rd_operator_interface/v1/vehicle_pb"
import { FeatureCollection, LineString, Point } from "geojson"
import { Region } from "lib/api/client"
import { useGetPath } from "lib/api/hooks/useGetPath"
import { useGetRemoteInterface } from "lib/api/hooks/useGetRemoteInterface"
import { useGetVehicle } from "lib/api/hooks/useGetVehicle"
import { useListPaths } from "lib/api/hooks/useListPaths"
import { useListSites } from "lib/api/hooks/useListSites"
import { useStreamVehicleLiveData } from "lib/api/hooks/useStreamVehicleLiveData"
import { useVehicleAssignPath } from "lib/api/hooks/useVehicleAssignPath"
import { useVehicleConnection } from "lib/api/hooks/useVehicleConnection"
import React, { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

export const ChoosePathView = (): React.JSX.Element => {
  const siteKey = "site"
  const { remoteInterface, vehicle } = useParams()
  if (remoteInterface === undefined) {
    throw new Error("station cannot be undefined")
  }
  if (vehicle === undefined) {
    throw new Error("vehicle cannot be undefined")
  }
  const navigate = useNavigate()
  const { toast } = useToasts()

  // Remote Interface fetching
  const [selectedRemoteInterface, setSelectedRemoteInterface] = useState<RemoteInterface>()
  const remoteInterfaceQueryEu = useGetRemoteInterface({
    req: { name: `remoteInterfaces/${remoteInterface}` },
    region: "EU",
  }).data
  const remoteInterfaceQueryUs = useGetRemoteInterface({
    req: { name: `remoteInterfaces/${remoteInterface}` },
    region: "US",
  }).data
  useEffect(() => {
    setSelectedRemoteInterface(remoteInterfaceQueryEu || remoteInterfaceQueryUs)
  }, [remoteInterfaceQueryEu, remoteInterfaceQueryUs])

  // Vehicle Fetching
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>()
  const [selectedVehicleRegion, setSelectedVehicleRegion] = useState<Region>("REGION_UNSPECIFIED")
  const { data: vehicleQueryEu } = useGetVehicle({
    name: `vehicles/${vehicle}`,
    region: "EU",
  })
  const { data: vehicleQueryUs } = useGetVehicle({
    name: `vehicles/${vehicle}`,
    region: "US",
  })
  useEffect(() => {
    setSelectedVehicle(vehicleQueryEu || vehicleQueryUs)
    setSelectedVehicleRegion(vehicleQueryEu ? "EU" : "US")
  }, [vehicleQueryEu, vehicleQueryUs])

  // Sites fetching
  const [sites, setSites] = useState<Site[] | undefined>(undefined)
  const [selectedSite, setSelectedSite] = useState<string>(sessionStorage.getItem(siteKey) || "")
  const sitesQueryEu = useListSites({
    region: "EU",
    pageSize: 1000,
  })
  useEffect(() => {
    setSites(sitesQueryEu.data)
  }, [sitesQueryEu.data])

  // Paths fetching
  const [selectedPath, setSelectedPath] = useState<Path | undefined>()
  const { paths, isLoading } = useListPaths({
    parent: selectedSite,
    region: "EU",
    pageSize: 1000,
  })
  const { path: selectedFullPath } = useGetPath({
    name: selectedPath?.name,
    region: "REGION_UNSPECIFIED",
  })

  const [vehicleLiveData, setVehicleLiveData] = useState<
    StreamVehicleLiveDataResponse | undefined
  >()
  const onNewData = useCallback(setVehicleLiveData, [setVehicleLiveData])
  const onStreamEnd = useCallback(
    (err?: ConnectError) => {
      if (!err) return

      if (err.code !== Code.DeadlineExceeded) {
        toast({ status: "fail", message: err.message ?? "unknown livedata error" })
      }
    },
    [toast],
  )

  useStreamVehicleLiveData({
    name: selectedVehicle?.name,
    onNewData,
    onStreamEnd,
    region: selectedVehicleRegion,
  })

  // Path selected/created by the user
  const [localPath, setLocalPath] = useState<FeatureCollection<LineString | Point>>()
  const [pathSheetOpen, setPathSheetOpen] = useState(false)
  const { isOpen, handlers } = useDisclosure(false)
  const { isOpen: settingsOpen, handlers: settingsHandler } = useDisclosure(false)

  const [selectedVersion, setSelectedVersion] = useState("")

  const {
    connect,
    disconnect,
    connectionState,
    connectionButtonString,
    isConnectionLoading,
    isConnectionReady,
  } = useVehicleConnection({
    region: selectedVehicleRegion,
    version: selectedVersion,
    remoteInterface: selectedRemoteInterface,
    vehicle: selectedVehicle,
  })

  const { assignPath, pathAssignProgress, isAssigningPath } = useVehicleAssignPath({
    region: selectedVehicleRegion,
    remoteInterface: selectedRemoteInterface,
    vehicle: selectedVehicle,
  })

  useEffect(() => {
    sessionStorage.setItem(siteKey, selectedSite)
  }, [selectedSite])

  useEffect(() => {
    if (sites && sites.length > 0 && !selectedSite) {
      setSelectedSite(sites[0].name ?? "")
    }
  }, [sites, selectedSite])

  const [pathsSearchTerm, setPathsSearchTerm] = useState("")
  const searcher = new FuzzySearch(paths || [], ["subSite", "displayName"], { sort: true })
  const filteredPaths: Path[] = searcher.search(pathsSearchTerm)
  const handlePathSearch = (searchString: string): void => {
    setPathsSearchTerm(searchString)
  }

  const handleSelectSite = (option: string): void => {
    setSelectedSite(option)
    setPathsSearchTerm("")
    setSelectedPath(undefined)
  }

  const handleSetSelectedPath = (path: Path): void => {
    if (path.name !== selectedPath?.name) {
      setSelectedPath(path)
    } else {
      // If a user selects an already selected path, unselect it
      setSelectedPath(undefined)
      setLocalPath(undefined)
    }
  }

  useEffect(() => {
    if (selectedFullPath === undefined || selectedFullPath.path === undefined) {
      return
    }
    setLocalPath(Base64ToFeatureCollection(selectedFullPath.path))
  }, [selectedFullPath])

  return (
    <Box display="flex">
      <TopRightFlexDiv>
        <IconButton
          aria-label="goBack"
          color="positive"
          icon="arrowLeft"
          onClick={() => {
            if (connectionState === "connected") {
              disconnect()
            }
            navigate("../..", { relative: "path" })
          }}
        >
          {" "}
        </IconButton>
        <SecondaryButton onClick={settingsHandler.open} rightIcon={<Cog />}>
          Settings
        </SecondaryButton>
      </TopRightFlexDiv>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="35vw"
        maxWidth="450px"
        padding="sm"
        height="100vh"
        background="secondary"
        gap="sm"
      >
        <Stack gap="none" justifyContent="flex-start" style={{ overflowY: "auto" }}>
          <Group gap="xs">
            <Skeleton height="lg" visible={!selectedVehicle}>
              <Title2
                style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
              >
                {selectedVehicle?.displayName}
              </Title2>
            </Skeleton>
            <Group justifyContent="space-between" flexWrap="nowrap" width="100%">
              <PrimaryButton
                isFullWidth
                rightIcon={
                  connectionState === "connected" ? (
                    <PlugDisconnected />
                  ) : (
                    <Icon name="arrowRight" />
                  )
                }
                disabled={isConnectionLoading || !isConnectionReady}
                isLoading={isConnectionLoading}
                onClick={connectionState === "disconnected" ? connect : disconnect}
                data-testid="connect-button"
              >
                {connectionButtonString}
              </PrimaryButton>
              <IconButton
                aria-label="connectWithName"
                variant="primary"
                disabled={connectionState !== "disconnected" || !isConnectionReady}
                icon="ellipsis"
                onClick={() => {
                  handlers.open()
                }}
              >
                {" "}
              </IconButton>
            </Group>
            <Tooltip
              content={!selectedPath ? "select a path" : ""}
              disabled={!!selectedSite && !!selectedPath}
            >
              <PrimaryButton
                isFullWidth
                rightIcon={<Icon name="arrowRight" />}
                disabled={
                  !selectedSite ||
                  !selectedPath ||
                  connectionState !== "connected" ||
                  isAssigningPath
                }
                onClick={() => {
                  assignPath(selectedFullPath)
                }}
                isLoading={isAssigningPath}
                data-testid="assign-vehicle-path-button"
              >
                {pathAssignProgress ?? "Assign vehicle path"}
              </PrimaryButton>
            </Tooltip>
            <Stack width="100%" style={{ overflowY: "auto" }}>
              <Skeleton height="auto" visible={sites === undefined}>
                <Select
                  value={selectedSite}
                  label="Select Site"
                  onChange={(e) => {
                    handleSelectSite(e.target.value)
                  }}
                >
                  {(sites || []).map((site) => {
                    return (
                      <option key={site.name} value={site.name}>
                        {site.displayName}
                      </option>
                    )
                  })}
                </Select>
              </Skeleton>
            </Stack>
          </Group>
          <Text color="secondary">Select Vehicle Path</Text>
          <Stack gap="xs" style={{ overflowY: "auto" }}>
            <SearchInput
              value={pathsSearchTerm}
              wrapperProps={{ width: "100%" }}
              placeholder="Search paths"
              aria-label="search paths"
              onInputChange={(searchString) => handlePathSearch(searchString)}
            />
            <Group gap="none" style={{ overflowY: "auto" }}>
              <Skeleton height="auto" visible={isLoading}>
                <Stack
                  gap="xs"
                  justifyContent="flex-start"
                  style={{ width: "-webkit-fill-available" }}
                >
                  {filteredPaths.length > 0 ? (
                    filteredPaths.map((path) => {
                      return (
                        <Card
                          background={path.name === selectedPath?.name ? "focus" : "secondary"}
                          key={path.name}
                          onClick={() => handleSetSelectedPath(path)}
                        >
                          <Group justifyContent="space-between" flexWrap="nowrap">
                            <Stack gap="none" style={{ width: "100%" }}>
                              <Group
                                style={{ width: "100%" }}
                                justifyContent="space-between"
                                alignItems="stretch"
                                flexWrap="nowrap"
                              >
                                <Text
                                  style={{ wordBreak: "break-word", textOverflow: "ellipsis" }}
                                  data-testid="path-name"
                                >
                                  {path.displayName}
                                </Text>
                                <Icon
                                  name="checkmark"
                                  style={{
                                    visibility:
                                      path.name === selectedPath?.name ? "visible" : "hidden",
                                  }}
                                />
                              </Group>
                              <Caption color="primary">{path.subSite}</Caption>
                            </Stack>
                          </Group>
                        </Card>
                      )
                    })
                  ) : (
                    <Text>No matching paths found</Text>
                  )}
                </Stack>
              </Skeleton>
            </Group>
          </Stack>
        </Stack>
        <Stack>
          <TertiaryButton
            isFullWidth
            onClick={() => {
              setPathSheetOpen(true)
            }}
          >
            Send unverified paths
          </TertiaryButton>
        </Stack>
      </Box>
      <GeoJSONMap
        localPath={localPath}
        vehicleLiveData={vehicleLiveData}
        vehicle={selectedVehicle}
      />
      <CustomPathSheet
        handleAssignPath={assignPath}
        setSelectedPath={setSelectedPath}
        pathSheetOpen={pathSheetOpen}
        setPathSheetOpen={setPathSheetOpen}
        localGeo={localPath}
        setLocalGeo={setLocalPath}
        pathAssignProgress={pathAssignProgress}
      />
      <SettingsSheet handlers={settingsHandler} isOpen={settingsOpen} />
      <Alert
        style={{ width: "200%" }}
        closeHandler={handlers.close}
        isOpen={isOpen}
        title="Connect with specific version"
        primaryAction={{
          children: "Connect",
          onClick: () => {
            connect()
            handlers.close()
          },
        }}
        secondaryAction={{ children: "Cancel", onClick: handlers.close }}
      >
        <TextInput
          label="Version"
          value={selectedVersion}
          onChange={(event) => {
            setSelectedVersion(event?.target.value)
          }}
        />
      </Alert>
    </Box>
  )
}
