import { ConnectError, Transport, createCallbackClient } from "@connectrpc/connect"
import { useToasts } from "components/Toast"
import { StreamVehicleLiveDataResponse } from "gen/einride/rd_operator_interface/v1/vehicle_live_pb"
import { VehicleService } from "gen/einride/rd_operator_interface/v1/vehicle_service_connect"
import { useAPITransport } from "lib/api/hooks/useAPITransport"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Region } from "../client"

interface Props {
  region: Region
  name: string | undefined
  // Gets called every time a message arrives.
  onNewData: (data: StreamVehicleLiveDataResponse) => void
  // Gets called on end of stream, regardless of error.
  onStreamEnd: (err?: ConnectError) => void
}

// Generate a "random" id
const toastID = Date.now()

export const useStreamVehicleLiveData = ({ name, onNewData, onStreamEnd, region }: Props): void => {
  const ts: Transport = useAPITransport(region)
  const client = useMemo(() => createCallbackClient(VehicleService, ts), [ts])
  const [lastStreamMessage, setLastStreamMessage] = useState<Date>()

  const { toast, dismiss } = useToasts()

  useEffect(() => {
    const checkMessageTime = (): void => {
      if (!lastStreamMessage) return
      const now = new Date()
      const diff = Math.round((now.getTime() - lastStreamMessage.getTime()) / 1000)
      // If we go more than 30 seconds without livedata, display an error to the user
      if (diff > 30) {
        toast({
          message: `${diff} seconds since the last livedata update`,
          status: "fail",
          id: toastID,
        })
      } else {
        dismiss(toastID)
      }
    }

    const interval = setInterval(checkMessageTime, 1000)
    return () => clearInterval(interval)
  }, [lastStreamMessage, dismiss, toast])

  const handleNewData = useCallback(
    (data: StreamVehicleLiveDataResponse) => {
      setLastStreamMessage(new Date())
      onNewData(data)
    },
    [onNewData],
  )

  useEffect(() => {
    if (!name) {
      return () => undefined
    }

    const cancel = client.streamVehicleLiveData({ name }, handleNewData, onStreamEnd)
    return cancel
  }, [client, name, handleNewData, onStreamEnd])
}
