import { UseDisclosureHandlers, usePrefersColorScheme } from "@einride/hooks"
import {
  Card,
  Paragraph,
  Segments,
  SegmentsItem,
  Sheets,
  Stack,
  Title3,
  useColorScheme,
} from "@einride/ui"
import React, { useEffect, useState } from "react"

type Props = {
  handlers: UseDisclosureHandlers
  isOpen: boolean
}

type SagaColorMode = "light" | "dark" | "system"
export type UnitSystem = "metric" | "US"

export const useUnitSystem = (): UnitSystem => {
  return localStorage.getItem("unitSystem") as UnitSystem
}

export const SettingsSheet = (props: Props): React.JSX.Element => {
  const { handlers, isOpen } = props

  const { setColorScheme } = useColorScheme()
  const systemColor = usePrefersColorScheme()
  const [selectedColor, setSelectedColor] = useState<SagaColorMode>(
    (localStorage.getItem("preferredColorTheme") as SagaColorMode) || "system",
  )
  const [unitSystem, setUnitSystem] = useState<UnitSystem>(
    (localStorage.getItem("unitSystem") as UnitSystem) || "metric",
  )

  useEffect(() => {
    localStorage.setItem("preferredColorTheme", selectedColor)
    if (selectedColor === "system") {
      setColorScheme(systemColor)
      return
    }
    setColorScheme(selectedColor)
  }, [systemColor, selectedColor, setColorScheme])

  const handleUnitSystem = (sys: UnitSystem): void => {
    localStorage.setItem("unitSystem", sys)
    setUnitSystem(sys)
  }

  return (
    <Sheets
      style={{ width: "360px" }}
      closeHandler={handlers.close}
      isOpen={isOpen}
      navigationAction={{
        "aria-label": "close-path-modal",
        icon: "xMark",
        onClick: handlers.close,
      }}
      navigationTitle={<Title3>Settings</Title3>}
    >
      <Stack gap="sm">
        <Card background="secondary">
          <Paragraph>Appearance</Paragraph>
          <Segments
            onValueChange={(value) => setSelectedColor(value as SagaColorMode)}
            value={selectedColor}
          >
            <SegmentsItem value="light">Light</SegmentsItem>
            <SegmentsItem value="dark">Dark</SegmentsItem>
            <SegmentsItem value="system">System</SegmentsItem>
          </Segments>
        </Card>
        <Card background="secondary">
          <Paragraph>Unit System</Paragraph>
          <Segments
            onValueChange={(value) => handleUnitSystem(value as UnitSystem)}
            value={unitSystem}
          >
            <SegmentsItem value="metric">Metric</SegmentsItem>
            <SegmentsItem value="US">Imperial</SegmentsItem>
          </Segments>
        </Card>
      </Stack>
    </Sheets>
  )
}
