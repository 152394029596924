import { Banner, IconButton } from "@einride/ui"
import styled from "@emotion/styled"
import React, { createContext, useCallback, useContext, useMemo, useState } from "react"

type ToastContextType = {
  toasts: Map<number, Toast>
  toast: (input: Toast) => number
  dismiss: (id: number) => void
}

type Toast = {
  id?: number
  status: "success" | "fail"
  message: string
}

const ToastContext = createContext<ToastContextType>({
  toasts: new Map<number, Toast>(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toast: () => {
    return 0
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dismiss: () => {},
})

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [toasts, setToasts] = useState<Map<number, Toast>>(new Map())

  const dismiss = useCallback((id: number): void => {
    setToasts((prevMap) => {
      const newMap = new Map(prevMap)
      newMap.delete(id)
      return newMap
    })
  }, [])

  const toast = useCallback(
    (input: Toast): number => {
      const id = input.id ?? Date.now()

      setToasts((prevMap) => {
        const newMap = new Map(prevMap)
        newMap.set(id, input)
        return newMap
      })

      if (input.status === "success") {
        setTimeout(() => dismiss(id), 10 * 1000)
      }

      return id
    },
    [dismiss],
  )

  const value = useMemo(() => ({ toasts, toast, dismiss }), [toasts, toast, dismiss])

  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useToasts = () => {
  return useContext(ToastContext)
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const Toasts = () => {
  const { toasts, dismiss } = useToasts()

  return (
    <ToastDiv>
      {Array.from(toasts.entries()).map(([id, toast]) => (
        <BannerDiv key={id} data-testid="banner">
          <Banner status={toast.status}>
            <DismissButtonDiv>
              <IconButton aria-label="dismiss" icon="xMark" onClick={() => dismiss(id)} />
            </DismissButtonDiv>
            <p data-testid="banner-text">{toast.message}</p>
          </Banner>
        </BannerDiv>
      ))}
    </ToastDiv>
  )
}

const DismissButtonDiv = styled.div`
  scale: 0.8;
  cursor: pointer;
`

const ToastDiv = styled.div`
  position: absolute;
  z-index: 1000;
  left: 50%;
  bottom: 0;
`

const BannerDiv = styled.div`
  position: relative;
  left: -50%;
  margin-bottom: 2vh;
`
