import {
  Box,
  Checkbox,
  Icon,
  IconButton,
  PrimaryButton,
  SearchInput,
  Table,
  TableSkeleton,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Title1,
  Tooltip,
  Tr,
} from "@einride/ui"
import { TopRightFlexDiv } from "components/FlexDiv"
import { LastSeenWidget } from "components/LastSeenWidget"
import { Logout } from "components/LogoutButton"
import FuzzySearch from "fuzzy-search"
import { RemoteInterface } from "gen/einride/rd_operator_interface/v1/remote_interface_pb"
import { Vehicle } from "gen/einride/rd_operator_interface/v1/vehicle_pb"
import { useGetRemoteInterface } from "lib/api/hooks/useGetRemoteInterface"
import { useListVehicles } from "lib/api/hooks/useListVehicles"
import React, { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

export const VehicleChooserView = (): React.JSX.Element => {
  const navigate = useNavigate()
  const [vehicles, setVehicles] = useState<Vehicle[] | undefined>(undefined)
  const [searchTerm, setSearchTerm] = useState("")
  const searcher = new FuzzySearch(vehicles || [], ["name", "displayName"])
  const filteredVehicles = searcher.search(searchTerm)
  const [sortingOrderAsc, setSortingOrderAsc] = useState<boolean>(true)
  const [sortedColumn] = useState<keyof Pick<Vehicle, "displayName" | "name">>("displayName")
  const [onlyLastSeen, setOnlyLastSeen] = useState<boolean>(true)

  const vehiclesQueryEu = useListVehicles({
    region: "EU",
    pageSize: 1000,
  })
  const vehiclesQueryUs = useListVehicles({
    region: "US",
    pageSize: 1000,
  })

  useEffect(() => {
    let dataEu = vehiclesQueryEu.data
    let dataUs = vehiclesQueryUs.data
    if (onlyLastSeen) {
      dataEu = dataEu.filter((v) => v.state?.lastSeenTime !== undefined)
      dataUs = dataUs.filter((v) => v.state?.lastSeenTime !== undefined)
    }
    setVehicles(dataEu.concat(dataUs))
  }, [vehiclesQueryEu.data, vehiclesQueryUs.data, onlyLastSeen])

  useMemo(() => {
    if (sortingOrderAsc) {
      filteredVehicles?.sort((a, b) =>
        (a?.[sortedColumn] ?? "").localeCompare(b[sortedColumn] ?? "", undefined, {
          numeric: true,
          sensitivity: "base",
        }),
      )
    } else {
      filteredVehicles?.sort((a, b) =>
        (b?.[sortedColumn] ?? "").localeCompare(a[sortedColumn] ?? "", undefined, {
          numeric: true,
          sensitivity: "base",
        }),
      )
    }
  }, [filteredVehicles, sortedColumn, sortingOrderAsc])

  const handleSearchChange = (searchString: string): void => {
    setSearchTerm(searchString)
  }

  const { remoteInterface } = useParams()
  if (remoteInterface === undefined) {
    throw new Error("station can not be undefined")
  }
  const [selectedRemoteInterface, setSelectedRemoteInterface] = useState<RemoteInterface>()

  const remoteInterfaceQueryEu = useGetRemoteInterface({
    region: "EU",
    req: { name: `remoteInterfaces/${remoteInterface}` },
  })
  const remoteInterfaceQueryUs = useGetRemoteInterface({
    region: "US",
    req: { name: `remoteInterfaces/${remoteInterface}` },
  })
  useEffect(() => {
    setSelectedRemoteInterface(remoteInterfaceQueryEu.data || remoteInterfaceQueryUs.data)
  }, [remoteInterfaceQueryEu, remoteInterfaceQueryUs])

  const findRoDtMismatch = (vehicle: Vehicle): React.JSX.Element => {
    const ro = vehicle.deployments?.find((depl) => depl.deployment === "remote-operate")
    const dt = vehicle.deployments?.find((depl) => depl.deployment === "deepthought")
    const mgnt = vehicle.deployments?.find((depl) => depl.deployment === "management")
    const vcu = vehicle.deployments?.find((depl) => depl.deployment === "vcu")

    const valid = !!ro && !!dt && !!mgnt && !!vcu
    const invalidVersion = valid && ro.version !== dt.version
    const iconName = invalidVersion ? "warning" : "checkmark"
    const versionText = invalidVersion ? "Version mismatch between RO and DT" : "Versions"

    return (
      <>
        {valid && (
          <Tooltip
            hint
            content={
              <Text>
                <b>{versionText}</b>
                <br />
                RO: {ro.version}
                <br />
                DT: {dt.version}
                <br />
                MGNT: {mgnt.version}
                <br />
                VCU: {vcu.version}
              </Text>
            }
          >
            <Icon name={iconName} />
          </Tooltip>
        )}
      </>
    )
  }

  const th = (
    <Thead>
      <Tr color="secondary">
        <Th
          style={{ cursor: "pointer" }}
          scope="col"
          onClick={() => {
            setSortingOrderAsc(!sortingOrderAsc)
            vehicles?.reverse()
          }}
        >
          Name
          <Icon
            style={{ paddingLeft: "0.5em", paddingRight: 0 }}
            name={sortingOrderAsc ? "chevronUp" : "chevronDown"}
            hidden={sortedColumn !== "displayName"}
          />
        </Th>
        <Th scope="col">RO version</Th>
        <Th scope="col">Status</Th>
        <Th scope="col" />
      </Tr>
    </Thead>
  )

  return (
    <Box display="flex">
      <TopRightFlexDiv>
        <IconButton
          icon="arrowLeft"
          aria-label="go-back"
          onClick={() => {
            navigate("../..", { relative: "path" })
          }}
        >
          {" "}
        </IconButton>
        <Logout>Log out</Logout>
      </TopRightFlexDiv>
      <Box
        alignItems="flex-start"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        padding="sm"
        height="100vh"
        width="100vw"
      >
        <Title1>Select vehicle for {selectedRemoteInterface?.displayName}</Title1>
        <Box padding="sm" height="100vh" width="100%">
          <SearchInput
            autoFocus
            wrapperProps={{ width: "100%" }}
            aria-label="searchVehicles"
            placeholder="Search vehicles"
            value={searchTerm}
            onInputChange={handleSearchChange}
          />
          <Box display="flex" justifyContent="flex-end">
            <Checkbox
              checked={onlyLastSeen}
              onCheckedChange={(checked) => {
                setOnlyLastSeen(checked)
              }}
            >
              <Tooltip
                maxInlineSize={50}
                content={
                  "Only show Vehicles that have a last seen time." +
                  " This hides vehicles that are not yet deployed but are added to AET registry."
                }
                hint
              >
                Only show vehicles with a last seen date
              </Tooltip>
            </Checkbox>
          </Box>
          {!vehiclesQueryEu.isLoading && !vehiclesQueryUs.isLoading && !!selectedRemoteInterface ? (
            <Table>
              {th}
              <Tbody>
                {(filteredVehicles.length > 0 &&
                  filteredVehicles.map((vehicle) => {
                    return (
                      <Tr key={vehicle.displayName}>
                        <Th scope="row">{vehicle.displayName}</Th>
                        <Th scope="row">
                          {vehicle.remoteVersion} {findRoDtMismatch(vehicle)}
                        </Th>
                        <Td>
                          <LastSeenWidget time={vehicle.state?.lastSeenTime} />
                        </Td>
                        <Td>
                          <PrimaryButton
                            isFullWidth
                            rightIcon={<Icon name="arrowRight" />}
                            onClick={() =>
                              navigate(`/${selectedRemoteInterface.name}/${vehicle.name}/drive`)
                            }
                          >
                            Select
                          </PrimaryButton>
                        </Td>
                      </Tr>
                    )
                  })) || (
                  <Tr>
                    <Td scope="row"> No vehicles found </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          ) : (
            <TableSkeleton columns={3} rows={5} thead={th} />
          )}
        </Box>
      </Box>
    </Box>
  )
}
